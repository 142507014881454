// 表格中时间转化
import Moment from "moment";
function timeFormatter(time, format = "YYYY-MM-DD") {
  if (time) {
    return Moment(time).format(format);
  } else {
    return "--";
  }
}
//年月日 时分秒格式转换\
function timeFormatterDetail(time, format = "YYYY-MM-DD HH:mm:ss") {
  if (time) {
    return Moment(time).format(format);
  } else {
    return "--";
  }
}

// 修改时区
const changeTimeZone = (data) => {
  let selectedDate = new Date(data);
  let currentTime = selectedDate.getTime();
  // 计算时区偏移量（以毫秒为单位，这里是8小时）
  let timezoneOffset = 8 * 60 * 60 * 1000;
  let adjustedTime = currentTime + timezoneOffset;
  let adjustedDate = new Date(adjustedTime);
  let stringData = adjustedDate.toISOString();

  const year = adjustedDate.getFullYear();
  const month = (adjustedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = adjustedDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

//获取最近十二小时的时间
function getRecentTwentyFourHours() {
  const currentDate = Moment(); // 获取当前日期时间
  const endDate = currentDate.startOf("hour").format("YYYY-MM-DD HH:59"); // 格式化结束时间YYYY-MM-DD HH:00

  const startDate = currentDate
    // .subtract(24, "hours")
    .startOf("hour")
    .format("YYYY-MM-DD 00:00"); // 计算并格式化开始时间YYYY-MM-DD HH:00

  return [startDate, endDate];
}
//获取最近七天
function getRecentSevenDays() {
  const endDate = Moment().format("YYYY-MM-DD 23:59"); // 格式化结束日期

  const startDate = Moment().subtract(6, "days").format("YYYY-MM-DD 00:00"); // 计算并格式化开始日期

  return [startDate, endDate];
}
//获取默认最近6月
function getRecentSixMonths() {
  const endDate = Moment().format("YYYY-MM-DD 23:59"); // 格式化结束日期

  const startDate = Moment()
    .subtract(6, "months")
    .startOf("month")
    .format("YYYY-MM-DD 00:00"); //1 计算并格式化开始日期

  return [startDate, endDate];
}
export {
  timeFormatter,
  timeFormatterDetail,
  changeTimeZone,
  getRecentTwentyFourHours,
  getRecentSevenDays,
  getRecentSixMonths,
};

<template>
  <div class="schedule-card" @click="handleView">
    <div class="card-header">
      <div class="schedule-name" v-showTip>
        <el-tooltip placement="bottom-start" effect="light">
          <template #content>
            {{ cardData.name }}
          </template>
          <div class="schedule-text">
            <span class="ellipse-text">
              {{ cardData.name }}
            </span>
          </div>
        </el-tooltip>
      </div>
      <div class="schedule-status">
        <ScheduleStatusTag :status="cardData.status"></ScheduleStatusTag>
      </div>
    </div>
    <div class="card-content">
      <div class="tag-list">
        <el-tag type="info">
          {{ SCHEDULE_TYPE_MAP[cardData.type]?.label }}
        </el-tag>
        <el-tag type="info">
          {{ EXECUTION_TYPE_MAP[cardData.executionType]?.label }}
        </el-tag>
        <el-tag type="info">
          关联数：{{ cardData.associatedProjectNum || 0 }}
        </el-tag>
      </div>
    </div>
    <div class="card-footer">
      <div class="update-time">
        {{ cardData.updateTime }}
      </div>
      <div class="more">
        <el-dropdown
          ref="dropdown1"
          placement="bottom"
          :disabled="ifOptionDisabled"
        >
          <div class="more-icon" @click.stop="() => {}">
            <i class="iconfont icon-gengduo"></i>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                @click="handleClickEnable"
                v-if="ifShowEffectButton()"
              >
                生效
              </el-dropdown-item>
              <el-dropdown-item
                v-if="
                  cardData.executionType == EXECUTION_TYPE.REPEATED &&
                  cardData.status === SCHEDULE_STATUS.ENABLED
                "
                @click="handleClickPause"
              >
                暂停
              </el-dropdown-item>
              <el-dropdown-item
                @click="handleClickEnable"
                v-if="ifShowReplayButton()"
              >
                重新执行
              </el-dropdown-item>
              <el-dropdown-item @click="handleClickEdit">编辑</el-dropdown-item>
              <el-dropdown-item @click="handleClickDelete">
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from "vue";

import ScheduleStatusTag from "@/components/base/ScheduleStatusTag.vue";

import { timeFormatter } from "@/utils/time.js";
import {
  SCHEDULE_STATUS_MAP,
  SCHEDULE_STATUS,
} from "@/constant/schedule_status.js";
import {
  SCHEDULE_TYPE_MAP,
  EXECUTION_TYPE_MAP,
  EXECUTION_TYPE,
} from "@/constant/schedule.js";

const emits = defineEmits(["edit", "delete", "enable", "pause", "view"]);
const props = defineProps({
  cardData: {
    type: Object,
  },
});
//是否显示更多里面的发布
const ifShowPublish = (row) => {
  if (row.isMarket == 0) {
    if (row.status != SCHEDULE_STATUS.NOTTESTED) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const ifOptionDisabled = ref(false);

const ifShowEffectButton = () => {
  let cardData = props.cardData;
  // 策略为未生效状态或策略为重复执行且策略为暂停中显示
  return (
    cardData.status == SCHEDULE_STATUS.DISABLED ||
    (cardData.status == SCHEDULE_STATUS.PAUSE &&
      cardData.executionType == EXECUTION_TYPE.REPEATED)
  );
};
const ifShowReplayButton = () => {
  let cardData = props.cardData;
  // 策略为立即执行且策略为已结束时显示
  return (
    cardData.status == SCHEDULE_STATUS.COMPLETED &&
    cardData.executionType == EXECUTION_TYPE.IMMEDIATE
  );
};

const handleClickDelete = () => {
  ifOptionDisabled.value = true;
  emits("delete", props.cardData);
};
const handleClickEdit = () => {
  // ifOptionDisabled.value = true;

  emits("edit", props.cardData);
};
const handleClickEnable = () => {
  ifOptionDisabled.value = true;

  emits("enable", props.cardData);
};
const handleClickPause = () => {
  ifOptionDisabled.value = true;

  emits("pause", props.cardData);
};

const handleView = () => {
  emits("view", props.cardData);
};
</script>

<style lang="scss" scoped>
.schedule-card {
  // height: 206px;
  // min-width: 300px;
  cursor: pointer;
  padding: 16px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  transition: box-shadow 0.3s, transform 0.3s;
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .schedule-name {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .schedule-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ellipse-text,
    .title {
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      text-align: left;
      font-family: SourceHanSansSC-bold;
      font-weight: 550;
    }
    .buy,
    .publish {
      width: 22px;
      height: 22px;
      margin-right: 6px;
    }
    .publish {
      color: #1a78ff;
      border-color: #3894ff26;
    }
    .schedule-status {
      margin-left: 20px;
    }
  }
  .card-content {
    margin-top: 20px;
    margin-bottom: 16px;
    color: rgba(108, 108, 108, 1);
    font-size: 14px;
    text-align: left;
    font-family: SourceHanSansSC-regular;
    .item {
      display: flex;
      .count {
        margin-left: 50px;
      }
    }
    .tag-list {
      margin-bottom: -8px;
      .el-tag {
        margin-right: 8px;
        margin-bottom: 8px;
        padding: 0 4px;
      }
    }

    .desc {
      padding: 16px 0;
    }
  }
  .card-footer {
    $footer-height: 36px;
    display: flex;
    justify-content: space-between;
    height: $footer-height;
    .update-time {
      height: $footer-height;
      line-height: $footer-height;
      color: rgba(108, 108, 108, 1);
    }
    .el-button {
      height: 36px;
      line-height: 20px;
      border-radius: 8px;
      background-color: rgba(247, 247, 247, 1);
      color: rgba(181, 180, 180, 1);
      font-size: 14px;
      text-align: center;
      border: 0;
      font-family: Roboto;
    }
    .el-button:hover {
      background-color: rgba(127, 68, 240, 0.06);
      color: rgba(127, 68, 240, 1);
    }
    .card-button {
      flex: 1;
    }

    .more {
      width: 24px;
      height: 24px;
      line-height: 36px;
      border-radius: 4px;
      position: relative;
      top: 6px;
      .more-icon {
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        color: rgba(181, 180, 180, 1);
      }
    }
    .more:hover {
      background: rgba(247, 247, 247, 1);
      .more-icon {
        // color: rgba(127, 68, 240, 1);
      }
    }
  }
}
.schedule-card:hover {
  box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.4);
  transform: translateY(-10px);
}
</style>

const SPIDER_TYPE = {
  CUSTOM: "custom", //启用中
  COMMON: "common", //禁用中
};

const SPIDER_TYPE_MAP = {
  [SPIDER_TYPE.CUSTOM]: {
    label: "专题爬虫",
  },
  [SPIDER_TYPE.COMMON]: {
    label: "通用爬虫",
  },
};

export { SPIDER_TYPE_MAP, SPIDER_TYPE };

export const EXECUTION_TYPE = {
  IMMEDIATE: 1,
  SCHEDULED: 2,
  REPEATED: 3,
};

export const EXECUTION_TYPE_MAP = {
  [EXECUTION_TYPE.IMMEDIATE]: {
    label: "立即执行",
  },
  [EXECUTION_TYPE.SCHEDULED]: {
    label: "定时执行",
  },
  [EXECUTION_TYPE.REPEATED]: {
    label: "重复执行",
  },
};

export const SCHEDULE_TYPE = {
  FULL: 1,
  INCREMENTAL: 2,
  MISSING: 3,
};
export const SCHEDULE_TYPE_MAP = {
  [SCHEDULE_TYPE.FULL]: {
    label: "全量采集",
  },
  [SCHEDULE_TYPE.INCREMENTAL]: {
    label: "增量采集",
  },
  [SCHEDULE_TYPE.MISSING]: {
    label: "缺失采集",
  },
};

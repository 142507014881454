export const ROLE = {
  TRIAL: 1,
  STANDARD: 2,
  PREMIUM: 3,
  CUSTOMIZED: 4,
};
export const ROLE_MAP = {
  [ROLE.TRIAL]: {
    label: "体验版",
  },
  [ROLE.STANDARD]: {
    label: "标准版",
  },
  [ROLE.PREMIUM]: {
    label: "高级版",
  },
  [ROLE.CUSTOMIZED]: {
    label: "定制版",
  },
};

const PROJECT_STATUS = {
  READY: 1, // 准备中
  ENABLE: 2, // 启动中
  PAUSE: 3, // 暂停中
};
const PROJECT_STATUS_MAP = {
  [PROJECT_STATUS.READY]: {
    label: "准备中",
    type: "info",
  },
  [PROJECT_STATUS.ENABLE]: {
    label: "启动中",
    type: "warning",
  },
  [PROJECT_STATUS.PAUSE]: {
    label: "暂停中",
    type: "primary",
  },
};
const PROJECT_STATUS_ACTION = {
  ENABLE: "启动",
  PAUSE: "暂停",
  READY: "准备",
};
const PROJECT_STATUS_ACTION_MAP = {
  [PROJECT_STATUS.ENABLE]: {
    action: PROJECT_STATUS_ACTION.ENABLE,
  },
  [PROJECT_STATUS.PAUSE]: {
    action: PROJECT_STATUS_ACTION.PAUSE,
  },
  [PROJECT_STATUS.READY]: {
    action: PROJECT_STATUS_ACTION.READY,
  },
};
const getStatusAction = (status) => {
  return PROJECT_STATUS_ACTION_MAP[status].action;
};
const PROJECT_STATUS_OPTION = Object.keys(PROJECT_STATUS_MAP).map((key) => ({
  label: PROJECT_STATUS_MAP[key].label,
  value: key,
}));
export {
  PROJECT_STATUS,
  PROJECT_STATUS_MAP,
  getStatusAction,
  PROJECT_STATUS_OPTION,
  PROJECT_STATUS_ACTION_MAP,
  PROJECT_STATUS_ACTION,
};

const TASK_STATUS_TYPE = {
  QUEUEING: 1, //排队中
  IN_PROGRESS: 2, //进行中
  // STOP: 2, //已暂停
  COMPLETED: 3, //已完成
  FAILED: 4, //已失败
};
const TASK_STATUS_MAP = {
  [TASK_STATUS_TYPE.QUEUEING]: { label: "排队中", color: "#9A9A9A" },
  [TASK_STATUS_TYPE.IN_PROGRESS]: { label: "进行中", color: "#F48F42" },
  // [TASK_STATUS_TYPE.STOP]: { label: "已暂停", color: "" },
  [TASK_STATUS_TYPE.COMPLETED]: { label: "已完成", color: "#08B809" },
  [TASK_STATUS_TYPE.FAILED]: { label: "已失败", color: "#E82727" },
};
const TASK_STATUS_OPTION = Object.keys(TASK_STATUS_MAP).map((key) => ({
  label: TASK_STATUS_MAP[key].label,
  value: key,
}));
export { TASK_STATUS_MAP, TASK_STATUS_OPTION, TASK_STATUS_TYPE };

<template>
  <div class="day-picker">
    <div class="day-list">
      <div
        class="day-item"
        :class="[
          type == 'month' && dayIndex == dayList.size - 1 ? 'last-day' : '',
          dayItem[1].ifSelected ? 'selected' : '',
          disabled ? 'disabled' : '',
        ]"
        v-for="(dayItem, dayIndex) in dayList"
        :key="dayItem[0]"
        @click="handleClickDayItem(dayItem)"
      >
        {{ dayItem[1].label }}
        <!-- {{  dayIndex == dayList.size - 1 }} -->
      </div>
    </div>

    <div class="tip" v-if="type == 'month'">
      如果本月不存在选择的日期，将忽略；如有重复，将只执行一次
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  computed,
  watch,
} from "vue";
import { useFormItem } from "element-plus";

const { formItem } = useFormItem();

const props = defineProps({
  type: {
    type: String,
    default: "month",
  },
  modelValue: {
    type: Array,
  },
  disabled: {
    type: Boolean,
    default: () => {
      return false;
    },
  },
});
const emits = defineEmits(["update:modelValue", "change"]);
const monthDayList = (() => {
  let result = new Map();
  for (let i = 1; i <= 31; i++) {
    result.set(i, { value: i, label: i, ifSelected: false });
  }
  // 最后一天的value为32
  result.set(32, { label: "最后一天", value: 32, ifSelected: false });
  return result;
})();

const weekDayList = new Map([
  [7, { value: 7, label: "周日", ifSelected: false }],
  [1, { value: 1, label: "周一", ifSelected: false }],
  [2, { value: 2, label: "周二", ifSelected: false }],
  [3, { value: 3, label: "周三", ifSelected: false }],
  [4, { value: 4, label: "周四", ifSelected: false }],
  [5, { value: 5, label: "周五", ifSelected: false }],
  [6, { value: 6, label: "周六", ifSelected: false }],
]);

const getDayLabel = (dayValue) => {
  return dayList.value.get(dayValue)?.label;
};

const dayList = ref([]);
watch(
  () => props.type,
  (type) => {
    if (type == "month") {
      dayList.value = monthDayList;
    } else if (type == "week") {
      dayList.value = weekDayList;
    }
  },
  {
    immediate: true,
  }
);
const handleClickDayItem = (dayItem) => {
  if (props.disabled) {
    return;
  }
  console.log("🚀 ~ handleClickDayItem ~ dayItem:", dayItem);
  dayItem[1].ifSelected = !dayItem[1].ifSelected;
  emits(
    "update:modelValue",
    Array.from(dayList.value)
      .filter((item) => item[1].ifSelected)
      .map((item) => item[1].value)
  );
  // emits("onChange");
  formItem?.validate("change"); // 触发校验
  console.log(
    "🚀 ~ handleClickDayItem ~ Array.f",
    Array.from(dayList.value)
      .filter((item) => item[1].ifSelected)
      .map((item) => item[1].value)
  );
};

watch(
  () => props.modelValue,
  (newVal) => {
    for (let [key, value] of dayList.value) {
      value.ifSelected = false;
    }
    for (let key of newVal) {
      dayList.value.get(key).ifSelected = true;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

defineExpose({ getDayLabel });
</script>

<style lang="scss" scoped>
.day-picker {
  width: 100%;
  background-color: rgba(239, 239, 239, 0.5);
  border-radius: 4px;
  padding: 20px;
  // padding-bottom: 16px;
  .day-list {
    display: grid;
    grid-template-columns: repeat(7, 60px);
    grid-auto-rows: 32px;
    grid-column-gap: 8px;

    grid-row-gap: 8px;
    place-items: center; /* 自动将内容水平垂直居中 */
    .day-item {
      background-color: rgb(255, 255, 255);
      height: 100%;
      width: 100%;
      border-radius: 4px;
      display: inline-block;
      text-align: center;
      font-size: 13px;

      justify-content: center;
      color: #6c6c6c;
      cursor: pointer;
      user-select: none;
      &.selected {
        color: rgba(127, 68, 240, 1) !important;
        background-color: rgba(127, 68, 240, 0.1) !important;
      }
      &.disabled {
        // color: #ebebeb;
        background: rgba(235, 235, 235, 1);
        // pointer-events: none;
        cursor: not-allowed !important;
      }
    }
    .last-day {
      font-size: 12px !important;
    }
  }

  .tip {
    color: #bebebe;
    font-size: 12px;
    height: 20px;
  }
}
</style>

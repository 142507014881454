<template>
  <div class="project-process-card">
    <div class="process-step">
      <el-steps :active="activeStep" class="step" align-center>
        <el-step title="创建项目">
          <template #icon>
            <div
              class="step-icon"
              @click="handleClickAddProject"
              :style="
                activeStep === PROCESS_STEP.STEP_ADD_PROJECT
                  ? 'cursor: pointer'
                  : ''
              "
            >
              <i class="iconfont icon-step icon-add-project icon-jiahao"></i>
              <i class="iconfont icon-finish icon-zhengque"></i>
            </div>
          </template>
        </el-step>
        <el-step title="配置爬虫">
          <template #icon>
            <div class="step-icon">
              <i class="iconfont icon-step icon-loudong"></i>
              <i
                class="iconfont icon-active icon-xinjian1"
                @click="handleClickConfigSpider"
              ></i>
              <i class="iconfont icon-finish icon-zhengque"></i>
            </div>
          </template>
        </el-step>
        <el-step title="执行采集">
          <template #icon>
            <div class="step-icon">
              <i class="iconfont icon-step icon-xietongrenwu"></i>
              <i
                class="iconfont icon-active icon-xinjian1"
                @click="handleClickCrawling"
              ></i>
              <!-- <i class="iconfont icon-pending icon-riqishijian_filled"></i> -->
              <i class="iconfont icon-finish icon-zhengque"></i>
            </div>
          </template>
        </el-step>
        <el-step title="获取数据">
          <template #icon>
            <div class="step-icon icon-running">
              <i class="iconfont icon-step icon-jurassic_data"></i>
              <i class="iconfont icon-finish icon-zhengque"></i>
            </div>
          </template>
        </el-step>
      </el-steps>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  computed,
  getCurrentInstance,
  watch,
  onMounted,
  nextTick,
} from "vue";
import { PROCESS_STEP } from "@/constant/process_step.js";

const emits = defineEmits(["add-project", "config-spider", "crawling"]);

const props = defineProps({
  activeStep: {
    //步骤
    type: Number,
    default: 0,
  },
});

//创建项目项目
const handleClickAddProject = () => {
  if (props.activeStep === PROCESS_STEP.STEP_ADD_PROJECT) {
    emits("add-project");
  }
};
//配置爬虫
const handleClickConfigSpider = () => {
  emits("config-spider");
};
//执行采集
const handleClickCrawling = () => {
  emits("crawling");
};
</script>

<style lang="scss" scoped>
.project-process-card {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .process-step {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 2px dashed rgba(224, 227, 234, 1);
    // padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .step {
      width: 100%;
      .step-icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid #e0e3ea;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .icon-step {
          font-size: 24px;
        }
        .icon-active {
          position: absolute;
          right: -5px;
          bottom: -5px;
          z-index: 1;
          color: #bebebe;
          font-size: 18px;
          cursor: pointer;
          display: none;
        }
        .icon-finish {
          position: absolute;
          right: -6px;
          bottom: -6px;
          z-index: 1;
          color: #08b809;
          font-size: 18px;
          display: none;
        }
      }
      :deep(.el-step__icon) {
        width: 60px;
        height: 60px;
      }
      :deep(.el-step__line) {
        top: 30px;
        background-color: #e0e3ea;
      }
      :deep(.el-step__title) {
        cursor: default;
      }
      :deep(.el-step__head.is-finish) {
        .el-step__icon {
          .step-icon {
            border: 1px solid $theme-color;
            background-color: $theme-color;
            color: #ffffff;
            .icon-finish {
              display: block;
            }
          }
        }
      }
      :deep(.el-step__head.is-process) {
        .el-step__icon {
          .step-icon {
            width: 60px;
            height: 60px;
            border: 1px solid $theme-color;
            background-color: $theme-color;
            color: #ffffff;
            .icon-step {
              font-size: 32px;
            }
            .icon-active {
              display: block;
            }
          }
          .icon-running {
            border: none;
            background: #7432ef; /* 初始背景颜色 */
            animation: colorChange 2s linear infinite;
          }
          @keyframes colorChange {
            0%,
            100% {
              background: #7432ef; /* 初始和结束的颜色 */
            }
            50% {
              background: #c0a1fc; /* 中间颜色 */
            }
          }
          .icon-add-project {
            cursor: pointer;
          }
        }
      }
      :deep(.el-step__head.is-wait) {
      }
      :deep(.el-step__title.is-finish) {
        font-weight: initial;
        color: $theme-color;
        font-size: 17px;
      }
      :deep(.el-step__title.is-process) {
        font-weight: initial;
        color: $theme-color;
        font-size: 17px;
      }
      :deep(.el-step__title.is-wait) {
      }
    }
  }
}
</style>

<template>
  <el-dialog
    v-model="DLGVisible"
    :title="DLGTitle"
    :before-close="closeDLG"
    append-to-body
    center
    width="1000px"
  >
    <div class="search-bar">
      <!-- <el-input
        placeholder="请输入关键词"
        style="width: 240px; margin-right: 24px"
      ></el-input> -->
      <el-select
        v-model="searchForm.keyword"
        filterable
        remote
        reserve-keyword
        :remote-method="remoteMethod"
        placeholder="请输入关键字"
        clearable
      >
        <el-option
          v-for="item in projectList"
          :key="item.projectUid"
          :label="item.name"
          :value="item.name"
        ></el-option>
      </el-select>
      <el-select
        v-model="searchForm.status"
        placeholder="请选择项目状态"
        style="width: 160px; margin-right: 24px"
      >
        <el-option
          v-for="item in projectStatusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-button type="primary" @click="getProjectList">查询</el-button>
    </div>
    <div class="list-table">
      <el-table
        :data="projectList"
        v-loading="tableLoading"
        ref="multipleTableRef"
        header-cell-class-name="list-table-header"
        @select="handleSelectionChange"
        @select-all="handleSelectionAllChange"
      >
        <el-table-column
          :selectable="selectEnable"
          type="selection"
          width="45px"
        />
        <el-table-column prop="name" label="项目名称" min-width="180">
          <template #default="scope">
            <TextEditor
              icon="icon-wodexiangmu"
              :icon-style="{ color: '#7F44F0' }"
              :text="scope.row.name"
              editable
              v-model:edit="scope.row.onEditing"
              v-model:loading="scope.row.ifShowLoading"
              @save="(text) => handleClickSaveName(text, scope.row)"
              @go="handleClickName(scope.row)"
            ></TextEditor>
          </template>
        </el-table-column>
        <el-table-column
          label="爬虫是否支持增量"
          width="150px"
          prop="isSupportIncrement"
          v-if="ifShowSupportIncrement"
        >
          <template #default="scope">
            <div>
              {{ scope.row.isSupportIncrement ? "是" : "否" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="项目状态" width="100">
          <template #default="scope">
            <ProjectStatusTag :status="scope.row.status" />
          </template>
        </el-table-column>
        <el-table-column prop="strategiesNum" label="有效策略数量" width="110">
          <template #default="scope">
            <div>
              {{ scope.row.strategiesNum ?? 0 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="collectNum" label="采集数量" width="110" />
        <el-table-column prop="createTime" label="创建时间" width="120">
          <template #default="scope">
            <div>
              {{ timeFormatter(scope.row.createTime) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" width="120">
          <template #default="scope">
            <div>
              {{ timeFormatter(scope.row.updateTime) }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          v-model:current-page="pageNum"
          v-model:page-size="pageSize"
          small
          background
          layout="total, prev, pager, next, sizes"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="goToPage"
        ></el-pagination>
      </div>
    </div>

    <div class="dialog-footer">
      <el-button @click="closeDLG">取消</el-button>
      <el-button type="primary" @click="handleClickConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  nextTick,
  computed,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { usePagination } from "@/utils/usePagination";
import { timeFormatter } from "@/utils/time.js";
import { ElMessage } from "element-plus";
import TextEditor from "@/components/base/TextEditor.vue";
import ProjectStatusTag from "@/components/base/ProjectStatusTag.vue";
import {
  PROJECT_STATUS,
  PROJECT_STATUS_OPTION,
} from "@/constant/project_status.js";
const emits = defineEmits(["confirm"]);
const props = defineProps({
  scheduleUid: {
    type: String,
    default: () => {
      return null;
    },
  },
  ifShowSupportIncrement: {
    type: Boolean,
    default: () => {
      return false;
    },
  },
});

const multipleTableRef = ref(null);
const schedulePageRelatedProjectList = ref([]);
const projectStatusOptions = ref(PROJECT_STATUS_OPTION);
const drawerType = ref("");
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("关联项目");
const scheduleUid = ref("");
const openDLG = (params) => {
  DLGVisible.value = true;
  init(params);
};
// const selectedItemList = ref([]);
const handleSelectionChange = (selection, row) => {
  if (selection.includes(row)) {
    schedulePageRelatedProjectList.value.push({ ...row, uid: row.projectUid });
  } else {
    const selectedIndex = schedulePageRelatedProjectList.value.indexOf(row);
    schedulePageRelatedProjectList.value.splice(selectedIndex, 1);
  }
};
const closeDLG = () => {
  DLGVisible.value = false;
};

const init = (params) => {
  scheduleUid.value = params.scheduleUid;
  drawerType.value = params.drawerType;
  schedulePageRelatedProjectList.value = JSON.parse(
    JSON.stringify(params.selectedProjectList)
  );
  getProjectList();
};
// 控制列表的复选框是否禁用
const ifCheckboxSelectable = (row) => {
  if (row.createUser == 0) {
    return 0;
  } else {
    return 1;
  }
};
const searchForm = ref({
  keyword: "",
  status: null,
});
// 全选项目
const handleSelectionAllChange = (selection, row) => {
  const selectedList = selection.map((item) => {
    return { ...item, uid: item.projectUid };
  });
  schedulePageRelatedProjectList.value = selectedList;
};
const projectList = ref([]);
const getProjectList = async () => {
  let params = {
    exclusionScheduleUid: null,
    ...searchForm.value,
    pageNum: pageNum.value,
    pageSize: pageSize.value,
  };
  // 新建策略状态调用带分页查询项目接口
  let res;
  if (drawerType.value === "EDIT") {
    params.exclusionScheduleUid = props.scheduleUid;
  }
  res = await httpPost("/project/v1/searchProjectByConditions", params);
  if (res.code == 0) {
    projectList.value = res.data.data;
    pageNum.value = res.data.pageNum;
    pageSize.value = res.data.pageSize;
    total.value = res.data.total;
    nextTick(() => {
      schedulePageRelatedProjectList.value.forEach((row) => {
        const item = projectList.value.find(
          (item) => item.projectUid === row.uid
        );
        // console.log(schedulePageRelatedProjectList.value);
        if (item) {
          multipleTableRef.value.toggleRowSelection(item, true);
        }
      });
    });
  }
};
const { pageNum, pageSize, total, goToPage, handleSizeChange } = usePagination({
  fetchDataFn: getProjectList,
  ifFetchOnMounted: false,
});
const selectEnable = (row) => {
  if (props.ifShowSupportIncrement) {
    return row.status !== PROJECT_STATUS.READY && row.isSupportIncrement;
  } else {
    return row.status !== PROJECT_STATUS.READY;
  }
  // return (
  //   row.status !== PROJECT_STATUS.READY &&
  //   props.ifShowSupportIncrement &&
  //   row.isSupportIncrement
  // );
};
// 点击确认
const handleClickConfirm = async () => {
  emits("confirm", schedulePageRelatedProjectList.value);
  closeDLG();
};

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/list-table.scss";

.search-bar {
  display: flex;
  margin-bottom: 24px;
  gap: 5px;
}
.dialog-footer {
  margin-top: 24px;
  text-align: end;
}
</style>

<template>
  <div
    id="chart"
    ref="chartRef"
    :style="{ width: '100%', height: '380px' }"
  ></div>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, nextTick } from "vue";
import * as echarts from "echarts";
import { timeFormatter } from "@/utils/time.js";

const props = defineProps({
  chartData: Object,
  timeType: String,
  selectTime: Array,
});

let myChart;
const chartRef = ref({});

const chartOption = {
  xAxis: {
    data: [],
    axisLabel: {
      showMinLabel: true,
      showMaxLabel: true,
    },
  },
  yAxis: {
    type: "value",
    axisLabel: {},
  },
  series: [
    {
      data: [],
      type: "line",
      lineStyle: {
        color: "#7F44F0",
      },
      itemStyle: {
        color: "#7F44F0",
      },
    },
  ],
  //辅助线配置
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "line",
      label: {
        backgroundColor: "#6a7985",
      },
      lineStyle: {
        type: "solid",
        color: "#7F44F0",
        opacity: 0.1,
        width: 50,
      },
    },
  },
};
const initChartView = () => {
  myChart = echarts.init(chartRef.value);
  // 绘制图表
  myChart.setOption(chartOption);
  nextTick(() => {
    myChart.resize();
  });
};

function formatDateToYearMonth(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  return `${year}-${month}`;
}

function formatDateToYearMonthHour(inputDate) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 月份从0开始，需要+1
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day} ${hours}时`;
  return formattedDate;
}

function generateHourRange(startTime, endTime) {
  let timeRange = [];
  let current = new Date(startTime);
  let end = new Date(endTime);
  timeRange.push(formatDateToYearMonthHour(new Date(current)));
  while (current < end) {
    current.setHours(current.getHours() + 1);
    timeRange.push(formatDateToYearMonthHour(new Date(current)));
  }
  return timeRange;
}
function generateMonthRange(startTime, endTime) {
  let timeRange = [];
  let current = new Date(startTime);
  let end = new Date(endTime);
  timeRange.push(formatDateToYearMonth(new Date(current)));
  while (current < end) {
    current.setMonth(current.getMonth() + 1);
    timeRange.push(formatDateToYearMonth(new Date(current)));
  }
  return timeRange;
}
function generateDayRange(startTime, endTime) {
  let timeRange = [];
  let current = new Date(startTime);
  let end = new Date(endTime);
  timeRange.push(timeFormatter(new Date(current)));
  while (current < end) {
    current.setDate(current.getDate() + 1);
    timeRange.push(timeFormatter(new Date(current)));
  }
  return timeRange;
}

watch(
  () => props.selectTime,
  (newVal, oldVal) => {
    if (props.timeType === "hour") {
      chartOption.xAxis.data = generateHourRange(newVal[0], newVal[1]);
    } else if (props.timeType === "day") {
      chartOption.xAxis.data = generateDayRange(newVal[0], newVal[1]);
    } else if (props.timeType === "month") {
      chartOption.xAxis.data = generateMonthRange(newVal[0], newVal[1]);
    }
    // chartOption.xAxis.axisLabel.formatter = function (value, index) {
    //   // 计算每个间隔的日期数量
    //   const remainingDates = chartOption.xAxis.data; // 剩余日期
    //   if (remainingDates.length <= 5) {
    //     // console.log(value);
    //     return value;
    //   } else {
    //     const intervalCount = Math.floor(remainingDates.length / 4); // 每个间隔的日期数量
    //     if (index % intervalCount === 0 && index / intervalCount < 4) {
    //       console.log(value);
    //       return value;
    //     } else if (index === remainingDates.length - 1) {
    //       return value;
    //     } else {
    //       return "";
    //     }
    //   }
    //   // const intervalCount = Math.ceil(remainingDates.length / 5); // 每个间隔的日期数量
    //   // if (index % intervalCount === 0) {
    //   //   console.log(value);
    //   //   return value;
    //   // } else {
    //   //   return "";
    //   // }
    //   // }
    // };
    // console.log(chartOption.xAxis.data);
  },
  { immediate: true, deep: true }
);

watch(
  () => props.chartData,
  (newVal, oldVal) => {
    chartOption.series[0].data = [];

    chartOption.xAxis.data.forEach((time) => {
      let value;
      if (props.timeType == "hour") {
        // 去掉末尾的时
        value = newVal[time.slice(0, -1)] ? newVal[time.slice(0, -1)] : 0;
      } else {
        value = newVal[time] ? newVal[time] : 0;
      }
      chartOption.series[0].data.push(value);
    });
    // console.log(chartOption.series[0].data);
    nextTick(() => {
      initChartView();
    });
  },
  { immediate: true, deep: true }
);
</script>

<style lang="scss" scoped></style>

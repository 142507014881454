<template>
  <el-drawer
    v-model="drawerVisible"
    :before-close="closeDrawer"
    :title="drawerTitle"
    custom-class="base-drawer"
    :size="700"
  >
    <div>
      <el-form
        :model="scheduleForm"
        :rules="rules"
        label-position="right"
        label-width="100"
        ref="formRef"
        class="schedule-form"
        :disabled="ifReadOnly"
        v-loading="ifFormLoading"
      >
        <el-form-item prop="name" label="策略名称：">
          <el-input v-model="scheduleForm.name" style="width: 250px"></el-input>
        </el-form-item>
        <el-form-item prop="type" label="策略类型：" required>
          <el-radio-group v-model="scheduleForm.type">
            <el-radio
              v-for="typeItem in SCHEDULE_TYPE"
              :key="typeItem"
              :label="typeItem"
            >
              {{ SCHEDULE_TYPE_MAP[typeItem].label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="executionType" label="执行类型：" required>
          <el-radio-group
            v-model="scheduleForm.executionType"
            @click="handleChangeExecutionType"
          >
            <el-radio
              v-for="executionTypeItem in EXECUTION_TYPE"
              :key="executionTypeItem"
              :label="executionTypeItem"
            >
              {{ EXECUTION_TYPE_MAP[executionTypeItem].label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="开始时间："
          required
          prop="durationTime"
          v-if="scheduleForm.executionType == EXECUTION_TYPE.REPEATED"
        >
          <div style="width: 350px">
            <el-date-picker
              time-format="HH:mm"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD HH:mm:ss"
              type="daterange"
              v-model="scheduleForm.durationTime"
              placeholder="请选择采集时间"
              range-separator="至"
              :disabled-date="ifDateDisabled"
            ></el-date-picker>
          </div>
        </el-form-item>
        <div
          style="display: flex; flex: 1"
          v-if="scheduleForm.executionType == EXECUTION_TYPE.REPEATED"
        >
          <el-form-item label="重复设置：" required prop="config.jobType">
            <div>
              <el-select
                v-model="scheduleForm.config.jobType"
                style="width: 80px; margin-right: 36px"
                @change="handleChangeJobType"
              >
                <el-option
                  v-for="jobTypeItem in jobTypeList"
                  :key="jobTypeItem.value"
                  :value="jobTypeItem.value"
                  :label="'按' + jobTypeItem.label"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item
            label=""
            prop="config.beApart"
            class="without-label error-offset-20 repeated-setting"
          >
            <span>
              <span>每</span>
              <el-input-number
                v-model="scheduleForm.config.beApart"
                :min="1"
                :max="maxBeApart"
                controls-position="right"
                style="width: 90px; margin: 0 8px"
              ></el-input-number>
              <span>{{ jobTypeStr }}</span>
            </span>
            <span class="result-tip" v-if="ifShowRepeatSettingResult">
              每
              <span class="variable">{{ scheduleForm.config.beApart }}</span>
              {{ jobTypeStr }}的
              <span class="variable">{{ repeatSettingDayStr }}</span>
              重复执行
            </span>
          </el-form-item>
        </div>
        <el-form-item
          prop="config.dayOfWeeks"
          label=""
          v-if="
            scheduleForm.executionType == EXECUTION_TYPE.REPEATED &&
            scheduleForm.config.jobType == JOB_TYPE.WEEK
          "
        >
          <div style="width: 510px">
            <DayPicker
              ref="weekDayPickerRef"
              type="week"
              v-model="scheduleForm.config.dayOfWeeks"
              :disabled="ifReadOnly"
            ></DayPicker>
          </div>
        </el-form-item>
        <el-form-item
          prop="config.dayOfMonths"
          label=""
          v-if="
            scheduleForm.executionType == EXECUTION_TYPE.REPEATED &&
            scheduleForm.config.jobType == JOB_TYPE.MONTH
          "
        >
          <div style="width: 510px">
            <DayPicker
              ref="monthDayPickerRef"
              v-model="scheduleForm.config.dayOfMonths"
              type="month"
            ></DayPicker>
          </div>
        </el-form-item>

        <el-form-item
          v-if="scheduleForm.executionType == EXECUTION_TYPE.SCHEDULED"
          label="采集时间："
          required
          prop="collectBeginTime"
        >
          <el-date-picker
            time-format="HH:mm"
            format="YYYY-MM-DD HH:mm"
            value-format="YYYY-MM-DD HH:mm:ss"
            type="datetime"
            v-model="scheduleForm.collectBeginTime"
            placeholder="请选择采集时间"
            :disabled-date="ifDateDisabled"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="采集时间："
          v-if="scheduleForm.executionType == EXECUTION_TYPE.REPEATED"
          required
          prop="collectBeginTime"
        >
          <el-time-picker
            time-format="HH:mm"
            value-format="YYYY-MM-DD HH:mm:ss"
            format="HH:mm"
            v-model="scheduleForm.collectBeginTime"
            placeholder="请选择采集时间"
          ></el-time-picker>
        </el-form-item>
        <div class="repeat-options">
          <el-form-item prop="duplicateUrlAllowed" label="URL重复">
            <el-select
              v-model="scheduleForm.duplicateUrlAllowed"
              style="width: 90px"
              :disabled="scheduleForm.type === SCHEDULE_TYPE.MISSING"
            >
              <el-option
                v-for="repeatItem in REPEATED_URL_OPTIONS"
                :key="repeatItem.value"
                :value="repeatItem.value"
                :label="repeatItem.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-tooltip placement="bottom" effect="light">
            <template #content>
              URL重复的配置如果选择允许，当采集时遇到重复的URL会仍然采集；
              <br />
              当URL重复的配置选择不允许，需要进一步进行重复设置，如果选择忽略，
              <br />
              则遇到重复的URL不会进行采集；如果选择更新，
              <br />
              则会将数据更新为最新一次的采集数据。
              <br />
              注意：当执行缺失采集时，遇到重复URL，系统默认更新。
            </template>
            <span class="question-icon">
              <i
                class="iconfont icon-wenhao-tishi"
                style="color: #bebebe; cursor: pointer; font-size: 16px"
              ></i>
            </span>
          </el-tooltip>

          <el-form-item
            prop="duplicateUrlHandleMethod"
            label="重复设置"
            v-if="
              scheduleForm.duplicateUrlAllowed === REPEATED_URL_OPTIONS[0].value
            "
          >
            <el-select
              v-model="scheduleForm.duplicateUrlHandleMethod"
              style="width: 90px"
              :disabled="scheduleForm.type === SCHEDULE_TYPE.MISSING"
            >
              <el-option
                v-for="repeatItem in REPEAT_OPTIONS"
                :key="repeatItem.value"
                :value="repeatItem.value"
                :label="repeatItem.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item prop="projectList" label="关联项目：">
          <div class="relate-project-form-item">
            <span>
              <i class="iconfont icon-wodexiangmu"></i>
              已关联项目数
              <span class="project-count">
                {{ scheduleForm.projectList?.length || 0 }}
              </span>
            </span>
            <el-button link @click="handleClickRelateProject" type="primary">
              <i class="iconfont icon-jiahao" style="margin-right: 8px"></i>
              关联项目
            </el-button>
          </div>
        </el-form-item>
        <el-table :data="scheduleForm.projectList" ref="projectTableRef">
          <el-table-column
            label="项目名称"
            prop="name"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="项目状态" prop="status" width="100">
            <template #default="scope">
              <ProjectStatusTag :status="scope.row.status" />
            </template>
          </el-table-column>
          <el-table-column
            label="采集数量"
            prop="collectNum"
            width="120"
          ></el-table-column>
          <el-table-column
            label="更新时间"
            prop="updateTime"
            width="120"
          ></el-table-column>
          <el-table-column label="" width="40">
            <template #default="scope">
              <i
                class="iconfont icon-shanchu1"
                style="display: none"
                :style="[drawerType == 'VIEW' ? 'display:none !important' : '']"
                @click="handleClickDeleteProject(scope)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    <template #footer v-if="drawerType != 'VIEW'">
      <div style="flex: auto">
        <!-- <el-button @click="handleClickCancel">取消</el-button> -->
        <el-button :disabled="confirmButtonDisabled" @click="handleClickSave">
          保存
        </el-button>
        <el-button
          :disabled="confirmButtonDisabled"
          type="primary"
          @click="handleClickSaveAndRun"
        >
          保存并生效
        </el-button>
      </div>
    </template>
  </el-drawer>
  <ScheduleDLGRelatedProject
    ref="scheduleDLGRelatedProjectRef"
    @confirm="onConfirmRelatedProjectList"
    :scheduleUid="scheduleForm.scheduleUid"
    :ifShowSupportIncrement="scheduleForm.type == SCHEDULE_TYPE.INCREMENTAL"
  ></ScheduleDLGRelatedProject>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  computed,
  defineExpose,
  watch,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { ElMessage } from "element-plus";
import {
  SCHEDULE_STATUS_MAP,
  SCHEDULE_STATUS,
} from "@/constant/schedule_status.js";
import {
  SCHEDULE_TYPE_MAP,
  SCHEDULE_TYPE,
  EXECUTION_TYPE_MAP,
  EXECUTION_TYPE,
} from "@/constant/schedule.js";
import ProjectStatusTag from "@/components/base/ProjectStatusTag.vue";

import ScheduleDLGRelatedProject from "./ScheduleDLGRelatedProject.vue";
import DayPicker from "./DayPicker";

const drawerVisible = ref(false);

const REPEATED_URL_OPTIONS = [
  {
    label: "不允许",
    value: 0,
  },
  {
    label: "允许",
    value: 1,
  },
];

const REPEAT_OPTIONS = [
  {
    label: "更新",
    value: "UPDATE",
  },
  {
    label: "忽略",
    value: "IGNORE",
  },
];

const openDrawer = (params) => {
  drawerVisible.value = true;
  init(params);
};
const init = (params) => {
  drawerType.value = params.type;
  if (drawerType.value == "VIEW" || drawerType.value == "EDIT") {
    getScheduleDetail(params.schedule.scheduleUid);
  } else {
    clearDrawer();
  }
};
const emits = defineEmits(["refresh"]);
const closeDrawer = () => {
  drawerVisible.value = false;
  clearDrawer();
};
const clearDrawer = () => {
  // formRef.value.resetFields();
  scheduleForm.value = JSON.parse(JSON.stringify(originalScheduleForm));

  formRef.value?.clearValidate();
};
const drawerType = ref("ADD"); //ADD VIEW EDIT
const drawerTitle = computed(() => {
  let result = "";
  switch (drawerType.value) {
    case "ADD":
      result = "新建策略";
      break;
    case "VIEW":
      result = "策略详情";
      break;
    case "EDIT":
      result = "编辑策略";
      break;
  }
  return result;
});

const formRef = ref(null);
const validateDay = (rule, value, callback) => {
  if (!value?.length) {
    callback(new Error("请选择重复日期"));
  } else {
    callback();
  }
};

const rules = ref({
  name: [{ trigger: "blur", required: true, message: "请填写策略名称" }],
  type: [{ trigger: "blur", required: true, message: "请选择策略类型" }],
  executionType: [
    { trigger: "blur", required: true, message: "请选择执行类型" },
  ],
  durationTime: [
    { trigger: "blur", required: true, message: "请选择开始时间" },
  ],
  duplicateUrlHandleMethod: [{ trigger: "blur", required: true }],
  collectBeginTime: [
    { trigger: "blur", required: true, message: "请选择采集时间" },
  ],
  duplicateUrlAllowed: [{ trigger: "blur", required: true }],
  "config.jobType": [
    { required: true, trigger: "change", message: "请选择重复频率" },
  ],
  "config.beApart": [{ required: true, trigger: "blur", message: "请输入" }],
  "config.dayOfWeeks": [{ validator: validateDay, trigger: "change" }],
  "config.dayOfMonths": [{ validator: validateDay, trigger: "change" }],
});

const ifDateDisabled = (date) => {
  let _date = new Date(date);
  let currentDate = new Date();

  _date.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);
  return _date < currentDate;
};

// 重复执行的选项列表
const JOB_TYPE = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
};
const jobTypeList = [
  { value: "DAY", label: "天" },
  { value: "WEEK", label: "周" },
  { value: "MONTH", label: "月" },
];
const originalScheduleForm = {
  scheduleUid: null,
  name: "", // 策略名称
  type: SCHEDULE_TYPE.FULL,
  executionType: EXECUTION_TYPE.IMMEDIATE, //执行类型

  collectBeginTime: "", //采集时间（定时执行为日期+时分；重复执行为时分）

  durationTime: [], // 重复执行的开始时间scheduleBeginTime|结束时间scheduleEndTime

  config: {
    dayOfWeeks: [], // 重复执行“按周”的具体执行日期（如周一）
    dayOfMonths: [], // 重复执行“按月”的具体执行日期（如1号、最后一天）
    jobType: JOB_TYPE.DAY, // 重复执行的频率单位
    beApart: 1, // 重复执行的间隔
  },

  projectUidList: [],
  projectList: [],
  duplicateUrlAllowed: null,
  duplicateUrlHandleMethod: "",
  //   {
  //     name: "name",
  //     status: 1,
  //     count: 11,
  //     updateTime: "2024-01-01",
  //   },
  // ],
};
const scheduleForm = ref(originalScheduleForm);
const ifFormLoading = ref(false);
const ifReadOnly = computed(() => {
  return drawerType.value == "VIEW";
});
// 获取策略详情
const getScheduleDetail = async (scheduleUid) => {
  ifFormLoading.value = true;
  let params = { scheduleUid: scheduleUid };
  let res = await httpPost("/schedule/v1/getScheduleByUid", params);
  if (res.code == 0) {
    scheduleForm.value = {
      ...res.data,
      durationTime: [res.data.scheduleBeginTime, res.data.scheduleEndTime],
    };
    if (
      scheduleForm.value.executionType == EXECUTION_TYPE.REPEATED &&
      scheduleForm.value.config?.jobType == "EVERY"
    ) {
      // 如果为重复执行且每天执行
      scheduleForm.value.config.beApart = 1;
      scheduleForm.value.config.jobType = JOB_TYPE.DAY;
    }
  }
  ifFormLoading.value = false;
};
// 重复设置中的最大间隔
const maxBeApart = computed(() => {
  let result = 365;
  switch (scheduleForm.value.config.jobType) {
    case JOB_TYPE.DAY:
      result = 180;
      break;
    case JOB_TYPE.WEEK:
      result = 30;
      break;
    case JOB_TYPE.MONTH:
      result = 12;
      break;
  }
  return result;
});

const handleChangeExecutionType = (val) => {
  // 清空后续配置
  scheduleForm.value.collectBeginTime = "";
  scheduleForm.value.durationTime = [];
  scheduleForm.value.config.jobType = JOB_TYPE.DAY;
  scheduleForm.value.config.beApart = 1;
  scheduleForm.value.config.dayOfWeeks = [];
  scheduleForm.value.config.dayOfMonths = [];
};

const handleChangeJobType = (val) => {
  scheduleForm.value.collectBeginTime = "";
  scheduleForm.value.config.beApart = 1;
  scheduleForm.value.config.dayOfWeeks = [];
  scheduleForm.value.config.dayOfMonths = [];
};

const handleChangeURLOptions = () => {
  // scheduleForm.value
};

// 重复执行的单位
const jobTypeStr = computed(() => {
  return jobTypeList.find((item) => {
    return item.value == scheduleForm.value.config.jobType;
  })?.label;
});

const weekDayPickerRef = ref(null);
const monthDayPickerRef = ref(null);

// 生成重复设置中最终结果的提示语
const repeatSettingDayStr = computed(() => {
  let dayList = [],
    result = "",
    dayPickerRef = null,
    unit = "";
  switch (scheduleForm.value.config.jobType) {
    case JOB_TYPE.WEEK:
      dayList = scheduleForm.value.config.dayOfWeeks;
      dayPickerRef = weekDayPickerRef;
      break;
    case JOB_TYPE.MONTH:
      dayList = scheduleForm.value.config.dayOfMonths;
      dayPickerRef = monthDayPickerRef;
      unit = "号";
      break;
    default:
  }
  result =
    dayList
      .slice(0, 2)
      .map(
        (item) =>
          dayPickerRef.value?.getDayLabel(item) +
          (item > 0 && item <= 31 ? unit : "")
      )
      .join("、") + (dayList.length > 2 ? "等" : "");

  return result;
});
const ifShowRepeatSettingResult = computed(() => {
  if (scheduleForm.value.executionType != EXECUTION_TYPE.REPEATED) {
    return false;
  }
  if (scheduleForm.value.config.jobType == "DAY") {
    return false;
  }
  return isReatedSettingOfWeekOrMonthValid();
});
// 检查重复设置月周是否合理
const isReatedSettingOfWeekOrMonthValid = () => {
  if (!scheduleForm.value.config.beApart) {
    return false;
  }
  if (
    scheduleForm.value.config.jobType == JOB_TYPE.WEEK &&
    scheduleForm.value.config.dayOfWeeks?.length
  ) {
    return true;
  }
  if (
    scheduleForm.value.config.jobType == JOB_TYPE.MONTH &&
    scheduleForm.value.config.dayOfMonths?.length
  ) {
    return true;
  }
  return false;
};
const projectTableRef = ref(null);
const handleClickDeleteProject = (scope) => {
  scheduleForm.value.projectList.splice(scope.$index, 1);
};
const onConfirmRelatedProjectList = (projectList) => {
  console.log("🚀 ~ onConfirmRelatedProjectList ~ projectList:", projectList);
  scheduleForm.value.projectList = projectList;
};

const confirmButtonDisabled = ref(false);
const handleClickSave = async () => {
  confirmButtonDisabled.value = true;
  formRef.value
    .validate()
    .then(async (res) => {
      if (!scheduleForm.value.scheduleUid) {
        await addSchedule();
      } else {
        await updateSchedule();
      }
      // confirmButtonDisabled.value = false;

      return;
    })
    .catch((error) => {
      console.log("🚀 ~ handleClickSave ~ error:", error);
      formRef.value.scrollToField(Object.keys(error)[0]);
      // confirmButtonDisabled.value = false;
    })
    .finally(() => {
      confirmButtonDisabled.value = false;
    });
};
const handleClickSaveAndRun = async () => {
  confirmButtonDisabled.value = true;
  formRef.value
    .validate()
    .then(async (res) => {
      if (!scheduleForm.value.scheduleUid) {
        await addAndRunSchedule();
      } else {
        await updateAndRunSchedule();
      }
      // confirmButtonDisabled.value = false;

      return;
    })
    .catch((error) => {
      console.log("🚀 ~ handleClickSave ~ error:", error);
      formRef.value.scrollToField(Object.keys(error)[0]);
      // confirmButtonDisabled.value = false;
    })
    .finally(() => {
      confirmButtonDisabled.value = false;
    });
};

const generateScheduleParams = () => {
  let params = {
    ...scheduleForm.value,
    durationTime: null,
    projectList: null,
  };
  if (scheduleForm.value.executionType == EXECUTION_TYPE.SCHEDULED) {
    params = {
      ...params,
      config: null, // 定时执行不需要config
    };
  } else if (scheduleForm.value.executionType == EXECUTION_TYPE.REPEATED) {
    let config = {
      ...params.config,
      hour: new Date(params.collectBeginTime).getHours(),
      minute: new Date(params.collectBeginTime).getMinutes(),
    };
    if (config.jobType == JOB_TYPE.DAY && config.beApart == 1) {
      //如果是每天执行，jobtype需要设置为EVERY,beapart设置为null
      config.jobType = "EVERY";
      config.beApart = null;
    }
    params = {
      ...params,
      scheduleBeginTime: scheduleForm.value.durationTime[0], //重复执行时需要config和开始结束时间
      scheduleEndTime: scheduleForm.value.durationTime[1],
      config,
    };
  } else {
    params = {
      ...scheduleForm.value,
      scheduleBeginTime: null,
      scheduleEndTime: null,
      config: null,
    };
  }
  params.projectUidList = scheduleForm.value.projectList.map(
    (item) => item.uid
  );
  console.log("🚀 ~ generateScheduleParams ~ params:", params);

  return params;
};
const addSchedule = async () => {
  let res = await httpPost(
    "/schedule/v1/saveSchedule",
    generateScheduleParams()
  );
  if (res.code == 0) {
    emits("refresh");
    closeDrawer();
    ElMessage.success("新建成功");
  }
  return res;
};
// 保存并运行
const addAndRunSchedule = async () => {
  let res = await httpPost(
    "/schedule/v1/saveAndEffectiveSchedule",
    generateScheduleParams()
  );
  if (res.code == 0) {
    emits("refresh");
    closeDrawer();
    ElMessage.success(res.data.promptMessage || "新建成功");
  }
  return res;
};

const updateSchedule = async () => {
  let res = await httpPost(
    "/schedule/v1/updateSchedule",
    generateScheduleParams()
  );
  if (res.code == 0) {
    emits("refresh");
    closeDrawer();
    ElMessage.success(res.data.promptMessage || "保存成功");
  }
  return res;
};
const updateAndRunSchedule = async () => {
  let res = await httpPost(
    "/schedule/v1/updateAndEffectiveSchedule",
    generateScheduleParams()
  );
  if (res.code == 0) {
    emits("refresh");
    closeDrawer();
    ElMessage.success(res.data.promptMessage || "操作成功");
  }
  return res;
};

watch(
  () => scheduleForm.value.type,
  (newVal) => {
    if (drawerType.value === "ADD") {
      if (
        newVal === SCHEDULE_TYPE.FULL ||
        newVal === SCHEDULE_TYPE.INCREMENTAL
      ) {
        scheduleForm.value.duplicateUrlAllowed = REPEATED_URL_OPTIONS[1].value;
      } else {
        scheduleForm.value.duplicateUrlAllowed = REPEATED_URL_OPTIONS[0].value;
        scheduleForm.value.duplicateUrlHandleMethod = REPEAT_OPTIONS[0].value;
      }
    } else {
      if (newVal === SCHEDULE_TYPE.MISSING) {
        scheduleForm.value.duplicateUrlAllowed = REPEATED_URL_OPTIONS[0].value;
        scheduleForm.value.duplicateUrlHandleMethod = REPEAT_OPTIONS[0].value;
      }
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

const handleClickCancel = () => {
  closeDrawer();
};

const scheduleDLGRelatedProjectRef = ref(null);
const handleClickRelateProject = () => {
  scheduleDLGRelatedProjectRef.value.openDLG({
    scheduleUid: scheduleForm.value.scheduleUid,
    selectedProjectList: scheduleForm.value.projectList,
    drawerType: drawerType.value,
  });
};

defineExpose({
  openDrawer,
  closeDrawer,
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/base-drawer.scss";
.schedule-form {
  .icon-wodexiangmu {
    color: $theme-color;
    margin-right: 8px;
  }
  .project-count {
    margin-left: 8px;
  }
  .repeated-setting {
    .result-tip {
      margin-left: 24px;
    }
    .variable {
      color: $theme-color;
    }
  }
  .relate-project-form-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
:deep(.el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell) {
  //   background: red;

  .iconfont {
    display: block !important;
    &:hover {
      cursor: pointer;
    }
  }
}
.without-label {
  // background: red;
  :deep(.el-form-item__content) {
    margin-left: 0px !important;
  }
}
.error-offset-20 {
  :deep(.el-form-item__error) {
    margin-left: 20px;
  }
}
.repeat-options {
  display: flex;
  align-items: center;
  margin-left: -15px;
  gap: 32px;
  .question-icon {
    margin-top: -20px;
    margin-left: -20px;
  }
}
</style>

<template>
  <el-dialog
    v-model="DLGVisible"
    :before-close="closeDLG"
    :title="DLGTitle"
    class="base-dialog"
    :close-on-click-modal="false"
  >
    <el-form
      ref="formRef"
      :model="form"
      :rules="formRules"
      style="width: 90%"
      label-position="right"
      label-width="100px"
    >
      <el-form-item prop="projectName" label="项目名称：">
        <el-input
          v-model="form.projectName"
          placeholder="请输入项目名称"
        ></el-input>
      </el-form-item>
      <el-form-item prop="projectDescription" label="项目描述：">
        <el-input
          type="textarea"
          :rows="3"
          v-model="form.projectDescription"
          placeholder="请输入项目描述"
        ></el-input>
      </el-form-item>
      <el-form-item prop="tagUidList" label="项目标签：">
        <el-select
          v-model="form.tagUidList"
          multiple
          placeholder="请选择项目标签"
        >
          <el-option
            v-for="item in tagOptions"
            :key="item.uid"
            :label="item.name"
            :value="item.uid"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        prop="spiderId"
        label="爬虫配置："
        v-if="formType === 'ADD'"
      >
        <el-select
          v-model="form.spiderId"
          filterable
          placeholder="请选择爬虫"
          :loading="selectLoading"
          @change="handleSelectSpider"
          clearable
        >
          <el-option
            v-for="item in spiderOptions"
            :key="item.spiderUid"
            :label="item.spiderName"
            :value="item.spiderUid"
          />
          <!-- + (item.disabled ? '（' + item.status + '）' : '')            
          :disabled="item.disabled"-->
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          @click="handleClickConfirm"
          :disabled="saveButtonDisable"
        >
          {{ formType === "ADD" ? "添加" : "保存" }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  nextTick,
  onMounted,
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import { Debounce } from "@/utils/utils";
import { ElMessage } from "element-plus";
import VueCookies from "vue-cookies";
import { remindErrorMessage } from "@/utils/utils";

const emits = defineEmits(["submit"]);
const route = useRoute();

// #region 表单数据相关
const form = ref({
  projectName: "",
  description: "",
  tagUidList: [],
  spiderId: "",
  // xsid: "",
  spiderName: "",
  // dataTypeName: "",
});
var validateName = async (rule, value, callback) => {
  value = value.trim();
  if (value === "") {
    callback(new Error("项目名称不能为空"));
  } else if (value.length < 2) {
    callback(new Error("项目名称最短为2位"));
  } else {
    if (oldProjectName.value !== value) {
      let res = await httpPost("/project/v1/verifyProjectNameIsDup", {
        projectName: value,
      });
      if (res.code == 0 && res.data) {
        callback(new Error("项目名称已存在"));
      } else {
        callback();
      }
    } else {
      callback();
    }
  }
};
const formRules = ref({
  projectName: [{ required: true, validator: validateName, trigger: "blur" }],
  // spiderId: [{ required: true, message: "请配置爬虫", trigger: "change" }],
});
const formType = ref("");
const formRef = ref(null);
const projectId = ref(null);
const oldProjectName = ref(null);
// 重置表格
const resetForm = () => {
  formRef.value.resetFields();
  formRef.value.clearValidate();
  form.value = {
    projectName: "",
    projectDescription: "",
    tagUidList: [],
    spiderId: "",
    // xsid: "",
    spiderName: "",
    // dataTypeName: "",
  };
  formType.value = "";
};
// #endregion 表单数据相关

// #region 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("新建采集项目");
const userId = ref(null);
const openDLG = (type, params) => {
  console.log("🚀 ~ openDLG ~ params:", params);
  formType.value = type;
  if (type == "EDIT") {
    if (params) {
      projectId.value = params.projectUid;
    }
    form.value = JSON.parse(JSON.stringify(params));
    form.value.projectName = params.projectName;
    form.value.tagUidList = params.projectTag?.map((item) => item.tagUid) || [];
    oldProjectName.value = form.value.projectName;
    DLGTitle.value = "编辑采集项目";
  } else {
    DLGTitle.value = "新建采集项目";
  }
  userId.value = VueCookies.get("userInfo").userId;
  getTagList();
  getSpiderList();
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  nextTick(() => {
    resetForm();
  });
};
// #endregion 弹窗相关

// #region 获取列表相关
//获取标签列表
const tagOptions = ref([]);
const getTagList = async () => {
  let res = await httpPost("/projectTag/v1/searchProjectTagAndNum", {});
  if (res.code == 0) {
    tagOptions.value = res.data;
  }
};
//获取爬虫列表
const selectLoading = ref(false);
const spiderOptions = ref([]);
const getSpiderList = async () => {
  selectLoading.value = true;
  let params = {};
  let res = await httpPost("/spider/v1/searchAllSpiderName", params);
  if (res.code == 0) {
    spiderOptions.value = res.data;
  }
  selectLoading.value = false;
};
const handleSelectSpider = (value) => {
  const obj = spiderOptions.value.find((item) => item.spiderUid === value);
  form.value.spiderId = obj.spiderUid;
  // form.value.xsid = obj.xsid;
  form.value.spiderName = obj.spiderName;
  // form.value.dataTypeName = obj.typeName;
};
// #endregion 获取列表相关

// 点击确认
const saveButtonDisable = ref(false);
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};
const handleClickConfirm = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      showSaveButtonDisable();
      if (formType.value == "ADD") {
        addProject();
      } else {
        saveProject();
      }
    }
  });
};
const addProject = Debounce(async () => {
  let params = {
    projectName: form.value.projectName,
    projectDescription: form.value.projectDescription,
    tagUidList: form.value.tagUidList,
    spiderUid: form.value.spiderId,
  };
  let res = await httpPost("/project/v1/addProject", params);
  if (res.code == 0) {
    ElMessage.success("添加成功");

    emits("submit");
    closeDLG();
  } else {
    remindErrorMessage(res.message);
  }
  hideSaveButtonDisable();
});
// 新增/修改项目
const saveProject = Debounce(async () => {
  let params;

  params = {
    projectUid: projectId.value,
    projectName: form.value.projectName,
    projectDescription: form.value.projectDescription,
    tagUidList: form.value.tagUidList,
  };
  //  {
  //   id: projectId.value,
  //   name: form.value.projectName,
  //   description: form.value.description,
  //   groupIds: form.value.groupIds,
  // };

  let res = await httpPost("/project/v1/editProject", params);
  if (res.code == 0) {
    ElMessage.success("编辑成功");
    emits("submit");
    closeDLG();
  } else {
    remindErrorMessage(res.message);
  }
  hideSaveButtonDisable();
});

onMounted(() => {
  getTagList();
});

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/base-dialog.scss";
:deep(.el-input__inner) {
  width: 240px;
}
</style>

const SCHEDULE_STATUS = {
  DISABLED: 1, //未生效
  ENABLED: 2, //已生效
  PAUSE: 3, //暂停中
  COMPLETED: 4, //已结束
};
const SCHEDULE_STATUS_MAP = {
  [SCHEDULE_STATUS.DISABLED]: {
    label: "未生效",
    type: "info",
  },
  [SCHEDULE_STATUS.ENABLED]: {
    label: "已生效",
    type: "warning",
  },
  [SCHEDULE_STATUS.PAUSE]: {
    label: "暂停中",
    // type: "success",
  },

  [SCHEDULE_STATUS.COMPLETED]: {
    label: "已结束",
    type: "success",
  },
};
export { SCHEDULE_STATUS_MAP, SCHEDULE_STATUS };

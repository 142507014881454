<template>
  <div :style="{ color: getColor }">
    <i class="iconfont icon-yuandian1"></i>
    {{ getLabel }}
  </div>
</template>

<script setup>
import { ref, defineProps, computed } from "vue";
import { TASK_STATUS_MAP } from "@/constant/task_status";
const props = defineProps({
  status: {
    type: Number,
  },
});
const getColor = computed(() => {
  return TASK_STATUS_MAP[props.status]?.color || "";
});

const getLabel = computed(() => {
  return TASK_STATUS_MAP[props.status]?.label || "";
});
</script>

<style lang="scss" scoped>
.iconfont {
  font-size: 10px;
}
</style>
